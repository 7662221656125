<template>
  <div>
    <Progress v-if="loading"/>
    <v-card
    tile
    v-if="!loading"
    >
    <v-card-text class="text-center">
        <v-container>
            <v-row justify="center" align="center">
                <v-col
                    cols="9"
                >
                <v-row>
                  <v-col cols="4">
                    <v-menu
                      v-model="from_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="from"
                          label="From"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          prepend-icon="mdi-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="from"
                        @input="from_menu = false"
                        @change="go_data()"
                        :max="to"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-menu
                      v-model="to_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="to"
                          label="To"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          prepend-icon="mdi-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="to"
                        @input="to_menu = false"
                        @change="go_data()"
                        :min="from"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                    :menu-props="{contentClass: 'dd-list-branch'}"
                    :items="branchs"
                    v-model="branch_id"
                    label="Branch"
                    item-value="id"
                    item-text="name"
                    @input="go_data()"
                  ></v-select>
                  </v-col>
                </v-row>
                </v-col>

                <v-col
                    cols="3"
                    align="right"
                >
                <download-excel :data="data" style="display:inline-block"
                :title="'Report Profit/loss from ' + from + ' to ' + to" :name="'Report Sales from ' + from + ' to ' + to +'.xls'"
                :footer="`Grand Total ${total}`"
                >
                <v-btn color="success" small depressed class="mr-2">
                  <v-icon small>mdi-microsoft-excel</v-icon>
                </v-btn>
                </download-excel>
                          <v-btn color="red" small depressed @click="downloadPdf()">
                        <v-icon small color="white">mdi-file-pdf-outline</v-icon>
                    </v-btn>

                </v-col>
            </v-row>
        </v-container>
          <h3 class="text-left">Grand Total : Rp. {{ this.total | numeral("0,0") }}</h3>
        <br>
        <v-data-table
          :headers="headers"
          :items="profit"
          :page.sync="page"
          :search="search"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
          @page-count="pageCount = $event"
          mobile-breakpoint="0"
          @click:row="openDetail"
        >
        <template v-slot:item.sales="{ item }" >
            <span>
               Rp. {{item.sales | numeral('0,0')}}
            </span>
        </template>
        <template v-slot:item.branch="{ item }" >
            <span>
              {{branch.name}}
            </span>
        </template>
        <template v-slot:item.periode="{ item }" >
            <span>
               {{from}}
            </span>
        </template>
        <template v-slot:item.expense="{ item }" >
            <span>
               Rp. {{item.expense | numeral('0,0')}}
            </span>
        </template>
        <template v-slot:item.purchase="{ item }" >
            <span>
               Rp. {{item.purchase | numeral('0,0')}}
            </span>
        </template>

        <template v-slot:item.total="{ item }" >
            <span>
               Rp. {{item | getTotal | numeral('0,0')}}
            </span>
        </template>

        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
          ></v-pagination>
          <v-text-field
            :value="itemsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div>
    </v-card-text>
    </v-card>
    <vue-html2pdf
      ref="html2Pdf"
      :paginate-elements-by-height="1400"
      pdf-content-width="1100px"
      pdf-orientation="landscape"
      :filename="`Report Profit form ${from} to ${to}`"
    >
    <templatePDF :headers="['branch','periode','Sales','Purchase','Expense','Mdr','Total']"
    :title="`Report Profit from ${from} to ${to}`"
    slot="pdf-content"
     >

    <tr v-for="item in data" :key="item.Document">
      <td>{{item.Branch}}</td>
      <td>{{item.Periode}}</td>
      <td>{{item.Sales}}</td>
      <td>{{item.Purchase}}</td>
      <td>{{item.Expense}}</td>
      <td>{{item.Mdr}}</td>
      <td>{{item.total}}</td>
    </tr>
    <tr>
      <td colspan="4">Grand Total</td>
      <td>{{ total }}</td>
    </tr>
    </templatePDF>
    </vue-html2pdf>
  </div>
</template>

<script>
// @ is an alias to /src
import Mixin from './mixin'
import {mapGetters} from 'vuex'
import downloadExcel from "vue-json-excel";
import VueHtml2pdf from 'vue-html2pdf'
import templatePDF from '../../../components/external/report-pdf'
export default {
  mixins:[Mixin],
  data() {
    return {
      data:[],
      pdf_data:'',
      total:0,
      headers: [
        {
          text: 'Branch',
          align: 'start',
          value: 'branch'
        },
        {
          text: 'periode',
          align: 'start',
          value: 'periode'
        },
        {
          text: 'Sales',
          align: 'start',
          value: 'sales'
        },
        {
          text: 'Purchase',
          value: 'purchase'
        },
        {
          text: 'Expense',
          value: 'expense'
        },
        {
          text: 'MDR',
          value: 'mdr'
        },
        {
          text: 'Total',
          value: 'total'
        },
      ]
    }
  },
  components: {
    downloadExcel,VueHtml2pdf,templatePDF,
  },
  computed: {
    ...mapGetters({
      profit: 'data/profit',
      branchs: 'data/branchs',
    }),
  },
  methods:{
    async go_data () {
      await this.axios.get('/v1/profit/daily?company_id=' + this.user.company_id + `&branch_id=${this.branch_id}&from=${this.from}&to=${this.to}` ,)
        .then(async (ress) => {
          console.log(ress.data.data)
          await this.setData({ data: ress.data.data, table_name: 'profit' })
            this.branch = this.branchs.find(x => x.id == this.branch_id)
            this.data = await this.profit.map(x => {
                  let total = parseInt(x.sales) - parseInt(x.purchase) - parseInt(x.expense)

            let diskon = parseInt(x.mdr)
            total = total - diskon
            let value = {
              Branch:this.branch.name,
              Periode:this.from,
              Sales:x.sales,
              Purchase:x.purchase,
              Expense:x.expense,
              Mdr:x.mdr,
              total:total,
            }

            return value
          })
          this.total = 0
          await this.data.forEach(x => {
            this.total = this.total + x.total
          })

        })
        .catch(err => console.log(err))
      this.loading = false
    },
     async downloadPdf() {
      this.dialog_loading = true
      await this.$refs.html2Pdf.generatePdf();
      this.dialog_loading = false
    },
    grandtotal() {
      let grandTotal = 0
      this.data.forEach(x => {
        grandTotal = grandTotal + x.total
      })
      this.total = grandTotal
    }
  },
  filters:{
    getTotal(x) {
      let total = parseInt(x.sales) - parseInt(x.purchase) - parseInt(x.expense)
      let diskon =parseInt(x.mdr)
      total = total - diskon
      return total
    },
  },
  async created() {
    if (this.profit.length) {
      this.loading = false
    }
  },
  async mounted () {
    await this.go('branch/all','branchs')
    this.branch_id = this.user.branch_id
    await this.go_data()

  }
}
</script>
